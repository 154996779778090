import React, {useContext, useState} from "react";
import { useHistory } from "react-router-dom";
import { modalStyles } from "components/_others/modals/modal-styles";
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Fade,
    InputLabel,
    Modal,
    TextField,
    Typography
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import { Payment } from "./Payment";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {UserContext} from "../../../../../core/context/user";
import {ObjectHighlights} from "components/object/ObjectHighlights";
import {ModalHeader} from "../../../../../components/_others/modals/ModalHeader";
import {IArtifact} from "../../../../../core/context/consumer/objectDetails";
import {UtilsContext} from "../../../../../core/context/utils.context";

const promise = loadStripe(
    "pk_live_zMaf3An1ZLFLYt5qmBAqqXaL00Wdzb5FfC"
);

type RequestAssessmentModalProps = {
    open: boolean;
    paymentFreePass: boolean;
    object: IArtifact;
    handleClose: () => void;
}

export const RequestAssessmentModal: React.FC<RequestAssessmentModalProps> = (
    {paymentFreePass, open, handleClose, object}
) => {

    const classes = modalStyles();
    const history = useHistory();
    const userContext = useContext(UserContext);

    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const utilsContext = useContext(UtilsContext);
    const [paymentData, setPaymentData] = useState({message: '', artifactId: -1, reload: 0});

    const handleSubmit = (): void => {
        setIsLoading(true);
        setPaymentData({message, artifactId: object.id, reload: paymentData.reload + 1});
    }

    const manageError = (error: string): void => {
        setIsLoading(false);
        console.log(error)
        let errorMessage = 'Sorry, there was an error. Please try again later.';
        if(error === 'AlreadyExists') {
            errorMessage = 'You already submitted a request for this object.'
        }
        utilsContext.addSnackbar('error', errorMessage)
    }

    return (
        <Modal
            className={classes.modal}
            style={{alignItems: 'flex-start', top: '20px', bottom: '20px'}}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <ModalHeader
                        title={'Request an Assessment'}
                        handleClose={() => handleClose()}
                    />

                    <Typography style={
                        {fontSize: '14px', color: userContext.lightTheme ? 'black' : '#EFEFEF', fontWeight: 'bold'}
                    }>
                        Total amount: <span style={{color: '#029BFE'}}>
                        {paymentFreePass ? '$0' : '$9'}
                    </span>
                    </Typography>

                    {
                        !paymentFreePass &&
                        <Typography style={{fontSize: '13px', color : userContext.lightTheme ? 'black' :  '#B2B2B2',
                            marginTop: '10px'}}>
                            One of Artifact's highly credible experts will provide an assessment of your object.
                            This assessment will include details and labels that you can import into the object record.
                        </Typography>
                    }

                    <ObjectHighlights
                        photo={object.photos.length ? object.photos[0]?.url : null}
                        title={object.title}
                        description={object.description}
                    />

                    <>
                        <InputLabel
                            htmlFor="message"
                            className="form-label"
                            style={{color: '#B2B2B2', fontSize: '13px', marginTop: '15px', marginBottom: '5px'}}
                        >
                            Add additional information for your reviewer (optional)
                        </InputLabel>
                        <TextField
                            multiline
                            rows={3}
                            rowsMax={10}
                            type="text"
                            id="message"
                            variant="outlined"
                            fullWidth
                            value={message}
                            inputProps={{ maxLength: 1000 }}
                            onChange={(e: any) => setMessage(e.target.value)}
                        />
                    </>

                    <Box style={paymentFreePass ? {display: 'none'} : {}}>
                        <InputLabel
                            htmlFor="message"
                            className="form-label"
                            style={{color: '#B2B2B2', fontSize: '13px', marginTop: '15px', marginBottom: '5px'}}
                        >
                            Enter your credit card information bellow:
                        </InputLabel>
                        <Elements stripe={promise}>
                            <Payment
                                paymentFreePass={paymentFreePass}
                                message={paymentData.message}
                                artifactId={paymentData.artifactId}
                                reload={paymentData.reload}
                                onSuccess={() => history.go(0)}
                                onError={(error: string) => manageError(error)}
                            />
                        </Elements>
                    </Box>

                    <Typography
                        className="error-message"
                        color="error"
                        style={{borderTop: '1px solid gray', paddingTop: 10}}
                    >
                        <b>Note: </b> you will not be able to edit your object after you request an assessment,
                        <b> but you will be able to upload new photos or documents.</b>
                    </Typography>

                    <Box textAlign={'right'} marginTop={'20px'}>
                        <Button
                            id="cancel-assessment-btn"
                            variant="contained"
                            size={"small"}
                            style={{marginRight: '15px'}}
                            className={classes.secondaryButton}
                            onClick={handleClose}
                            disabled={isLoading}
                        >
                            <CloseIcon height={'20px'} /> Cancel
                        </Button>

                        <Button
                            id="delete-btn"
                            variant="contained"
                            color="primary"
                            size={"small"}
                            className={classes.primaryButton}
                            onClick={() => handleSubmit()}
                            disabled={isLoading}
                        >
                            {
                                isLoading && <CircularProgress className="circular-progress sm" />
                            }
                            {
                                !isLoading && !paymentFreePass &&
                                <MonetizationOnIcon style={{width: '20px', marginRight: '3px'}} />
                            }
                            Request Assessment
                        </Button>
                    </Box>
                </div>
            </Fade>
        </Modal>
    )
}

import axios from 'axios';

import {getJobIdFromStorage, getRefreshToken, getToken, setToken} from "core/helpers/storage-helper";

const axiosInstance = axios.create({
    baseURL: `https://artifact.id/api/`,
});

axiosInstance.interceptors.request.use((config) => {
    if(config.url?.indexOf('collectors/get/assignedTo') === -1) {
        config.params = {...config.params, userId: getJobIdFromStorage()}
    }
    return config;
// eslint-disable-next-line @typescript-eslint/promise-function-async
}, (error: any) => {
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;

        console.log(error?.response)
        if(error?.response?.data?.message === 'UserNotActive' && error?.response?.config?.url !== 'users/login') {
            localStorage.clear();
            //location.reload();
            return;
        }

        if ((error?.response?.status === 401 ) && Boolean(getRefreshToken())) {
            return axios
                .create({
                    baseURL: `https://artifact.id/api/`,
                    headers: { Authorization: `Bearer ${getToken()}` },
                })
                .put(`user/token/refresh`, {token: getRefreshToken()})
                // eslint-disable-next-line @typescript-eslint/promise-function-async
                .then((response) => {
                    setToken(response.data.token, response.data.refreshToken);

                    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${getToken()}`;
                    originalRequest.headers['Authorization'] = `Bearer ${getToken()}`;
                    return axiosInstance(originalRequest);
                })
                .catch((error: any) => {
                    console.error(error);
                    window.localStorage.clear();
                    window.location.replace("/");
                });
        }
        return Promise.reject(error);
    },
);

export default axiosInstance;
